import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import cx from 'classnames';
import { gsap } from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';

import { Button } from '../Button/Button';
import { useCalCom } from '../../utils/useCalCom';
import { useSiteMetadata } from '../../utils/useSiteMetadata';
import { track } from '../../utils/analytics';
import { Clickable } from '../Clickable/Clickable';
import { Icon } from '../Icon/Icon';
import { Link } from '../Link/Link';

import iraImage from './assets/ira.webp';

import * as cs from './ConsultationCtaMini.module.css';

gsap.registerPlugin(ScrollTrigger, TextPlugin);

const SCROLL_EVENT_NAME = 'book-call-view-widget-cta-mini';
const OPEN_EVENT_NAME = 'book-call-open-widget-cta-mini';

const BANNER_IMAGE = {
  ira: iraImage,
  ira_chat: iraImage,
};

interface Props extends React.HTMLAttributes<HTMLElement> {
  calComLink?: string | null;
  openEventName?: string;
  scrollEventName?: string;
  kind?: 'ira' | 'ira_chat';
  text?: string;
  buttonText?: string | null;
  align?: 'max' | 'content';
  needEmail?: boolean;
  textLg?: boolean;
  className?: string;
  description?: string | null;
}

export const ConsultationCtaMini = ({
  calComLink,
  openEventName = OPEN_EVENT_NAME,
  scrollEventName = SCROLL_EVENT_NAME,
  kind = 'ira',
  text = undefined,
  buttonText = undefined,
  needEmail = false,
  textLg = false,
  className,
  description,
  ...restProps
}: Props) => {
  const { consultationCalCom, leadEmail } = useSiteMetadata();
  const wrapperRef = React.useRef(null);
  // this variable is needed to track the event only once
  const [isTracked, setIsTracked] = React.useState(false);
  const scrollEventNameByKind = `${scrollEventName}-${kind}`;
  const openEventNameByKind = `${openEventName}-${kind}`;

  React.useEffect(() => {
    if (!('IntersectionObserver' in window)) {
      return;
    }

    const onIntersect: IntersectionObserverCallback = (entries) => {
      if (!entries || isTracked) {
        return;
      }

      if (entries[0].isIntersecting) {
        track(scrollEventName);
        track(scrollEventNameByKind);
        setIsTracked(true);

        const indicator = entries[0].target.querySelector(`.${cs.indicator}`);
        indicator?.classList?.add(cs.pulse);
        const description = entries[0].target.querySelector(
          `.${cs.description}`,
        );
        description?.classList?.add('type');
      }
    };

    const observer = new IntersectionObserver(onIntersect, {
      root: null,
      rootMargin: '0px',
      threshold: [1],
    });

    const node = wrapperRef && wrapperRef.current;

    if (node && observer) {
      observer.observe(node);
    }

    return () => {
      if (observer) {
        if (node) {
          observer.unobserve(node);
        }
        observer.disconnect();
      }
    };
  }, [isTracked, scrollEventNameByKind, scrollEventName]);

  const data = useStaticQuery(graphql`
    query ConsultationMiniQuery {
      snippet(slug: { eq: "consultation_mini" }) {
        id
        data
      }
    }
  `);
  const snippet: CustomTypes.ConsultationMiniSnippet = data.snippet.data;

  const calComProps = useCalCom({ link: calComLink || consultationCalCom });
  const clickEvents = [openEventName, openEventNameByKind];
  const clickableProps = { trackEvent: clickEvents, ...calComProps };

  const isClickableBlock = kind === 'ira_chat';
  // ira_chat kind has sets text via gsap animation
  const descriptionText =
    kind === 'ira_chat' ? '' : text || description || snippet.text;

  const Block = isClickableBlock ? Clickable : 'article';

  useGSAP(
    () => {
      const targetElements: HTMLElement[] = gsap.utils.toArray(
        `.${cs.ira_chatDescription}`,
      );

      if (targetElements.length) {
        gsap.to(targetElements[0], {
          scrollTrigger: {
            trigger: targetElements[0],
            start: 'top 90%',
          },
          text: {
            value: text || snippet.text,
          },
          duration: 2,
        });
      }
    },
    { scope: wrapperRef, dependencies: [calComProps.to] },
  );

  return (
    <section className={cx(cs.wrapper, className)} ref={wrapperRef}>
      <Block
        {...(isClickableBlock ? clickableProps : null)}
        {...restProps}
        className={cx(cs.block, cs[`${kind}Block`])}
      >
        <div className={cx(cs.imageContainer, cs[`${kind}ImageContainer`])}>
          <div
            className={cx(
              cs.indicatorContainer,
              cs[`${kind}IndicatorContainer`],
            )}
          >
            <img
              className={cx(cs.image, cs[kind])}
              src={BANNER_IMAGE[kind]}
              alt={snippet.button}
            />
            <div className={cs.indicatorBG}>
              <div className={cs.indicator}></div>
            </div>
          </div>
        </div>

        <p className={cs.title}>
          <b>Irina Nazarova</b> <small>CEO at Evil Martians</small>
        </p>

        <p
          className={cx(
            cs.description,
            cs[`${kind}Description`],
            textLg && cs.textLg,
          )}
          dangerouslySetInnerHTML={{
            __html: descriptionText,
          }}
        />
        <div className={cs.buttonsContainer}>
          <Button
            variant="solid"
            theme="primary"
            size={kind === 'ira' ? 'lg' : 'md'}
            hoverable={kind === 'ira'}
            className={cx(cs.button, cs[`${kind}Button`])}
            type={isClickableBlock ? 'tag' : 'button'}
            {...(isClickableBlock ? {} : clickableProps)}
          >
            {buttonText || snippet.button}
          </Button>

          {needEmail && (
            <div className={cs.sendEmail}>
              <Icon name="email" size={20} />
              <span>
                <Link trackEvent="contact-us-email" to={`mailto:${leadEmail}`}>
                  Send email
                </Link>
                &nbsp;instead
              </span>
            </div>
          )}
        </div>
      </Block>
    </section>
  );
};
